<template>
  <div :class="[isMobile ? 'mobile-about-introduction' : '', 'about-introduction']">
    <item-title v-if="!isMobile" :title="{name: '明亚简介', en_1: 'MINGYA', en_2: 'PROFILE'}" />
    <div v-if="introData" class="introduction-content">
      <section class="section-1">
        <div :style="{backgroundImage: 'url('+video_bg+')'}" class="video-box">
          <img v-if="isMobile" class="bg" :src="video_bg" alt="">
          <!-- <img @click="isShowVideoBox = true" class="btn-play" src="../../assets/img/aboutus/player.png" alt=""> -->
        </div>
        <div class="company">
          <div class="company-name">
            <div class="en">MINGYA</div>
            <div class="cn">明亚保险经纪股份有限公司</div>
          </div>
          <div class="brief">
            <pre>{{company_intro_brief}}</pre>
          </div>
        </div>
      </section>
      <section class="section-2">
        <img :src="company_intro_img" alt="">
      </section>
      <section class="section-3">
        <pre>{{company_intro}}</pre>
      </section>
      <section class="section-4">
        <img src="../../assets/img/aboutus/quote1.png" alt="">
        <div class="slogan">
          <pre>{{company_slogan}}</pre>
        </div>
        <img src="../../assets/img/aboutus/quote2.png" alt="">
      </section>
    </div>
    <div v-if="video_data.length > 0" class="videos-box">
       <div v-if="isMobile" class="carouse-container mobile-carouse">
        <el-carousel indicator-position="none" :interval="3000" arrow="always">
          <el-carousel-item  v-for="(item, index) in video_data" :key="index">
            <img style="width: 100%;" :src="item.content.cover_url" alt="">
            <img style="position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); width: .58rem; height: .58rem;" @click="playVideo(item)" class="btn-play" src="../../assets/img/aboutus/player.png" alt="">
            <div class="name" style="background-color: rgba(0, 0, 0, .6); width: 100%; height: .5rem; position: absolute; left: 0; bottom: 0; font-size: .18rem; color: #ffffff; padding: 0 .24rem; line-height: .5rem;">{{item.position}}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-else class="carouse-container pc-carouse">
        <el-carousel :interval="3000" type="card" height="6.04rem">
          <el-carousel-item style="height: 3.4rem; width: 6.04rem;" v-for="(item, index) in video_data" :key="index">
            <img style="height: 3.4rem; width: 6.04rem;" :src="item.content.cover_url" alt="">
            <img style="position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); width: .58rem; height: .58rem;" @click="playVideo(item)" class="btn-play" src="../../assets/img/aboutus/player.png" alt="">
            <div class="name" style="background-color: rgba(0, 0, 0, .6); width: 100%; height: .5rem; position: absolute; left: 0; bottom: 0; font-size: .18rem; color: #ffffff; padding: 0 .24rem; line-height: .5rem;">{{item.position}}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div @click="dialogVisible = true" v-if="video_data_origin.length > 3" class='more-video'>更多 <img src="../../assets/img/common/common_add_white.png" alt=""></div>
    </div>
    <div class="main-business">
      <div v-if="isMobile" class="head">
        <div class="cn">主营业务</div>
      </div>
      <div v-else class="head">
        <div class="cn">主营业务&emsp;/</div>
        <div class="en">
          <span>MAIN</span>
          <span>BUSINESS</span>
        </div>
      </div>
      <div @click="jumpService" v-if="isMobile" class="mobile-business-list">
        <div v-for="(item, index) in main_business" :style="{backgroundImage: 'url('+item.content.backgroundImg+')'}" :key="index" class="list-item">
          <div class="content-box">
            <img class="zw" src="../../assets/img/aboutus/life.jpg" alt="">
            <div class="desc">
              <img class="icon" :src="item.content.icon" alt="">
              <div class="name">{{item.content.name_cn}}</div>
              <div class="name-en">{{item.content.name_en}}</div>
            </div>
          </div>
        </div>
        <!-- <div class="list-item">
          <div class="content-box">
            <img class="zw" src="../../assets/img/aboutus/life.jpg" alt="">
            <div class="desc">
              <img class="icon" src="../../assets/img/aboutus/property.png" alt="">
              <div class="name">财产险业务</div>
              <div class="name-en">GENERAL&nbsp;&emsp;INSURANCE</div>
            </div>
          </div>
        </div> -->
        
        <!-- <div class="list-item">
          <div class="content-box">
            <img class="zw" src="../../assets/img/aboutus/life.jpg" alt="">
            <div class="desc">
              <img class="icon" src="../../assets/img/aboutus/personal.png" alt="">
              <div class="name">个人客户</div>
              <div class="name-en">RETAIL&nbsp;&emsp;CLIENT</div>
            </div>
          </div>
        </div>
        <div class="list-item">
          <div class="content-box">
            <img class="zw" src="../../assets/img/aboutus/life.jpg" alt="">
            <div class="desc">
              <img class="icon" src="../../assets/img/aboutus/group.png" alt="">
              <div class="name">团体客户</div>
              <div class="name-en">GROUP&nbsp;&emsp;CLIENT</div>
            </div>
          </div>
        </div> -->
      </div>
      <div v-else class="business-list">
        <div v-for="(item, index) in main_business" :key="index" :style="{backgroundImage: 'url('+item.content.backgroundImg+')'}" class="list-item">
          <div class="content-box">
            <div class="desc">
              <img class="icon" :src="item.content.icon" alt="">
              <div class="name">{{item.content.name_cn}}</div>
              <div class="name-en">{{item.content.name_en}}</div>
            </div>
            <a
            class="btn-more"
            href="https://v7-webchat.7moor.com/?accessId=ILwj5de78f54oT74&imUrlTitle=&imFromUrl="
            target="_blank"
          >咨询了解 +</a>
          </div>
        </div>
        <!-- <div class="list-item">
          <div class="content-box">
            <div class="desc">
              <img class="icon" src="../../assets/img/aboutus/property.png" alt="">
              <div class="name">财产险业务</div>
              <div class="name-en">GENERAL&nbsp;&emsp;INSURANCE</div>
            </div>
            <a
            class="btn-more"
            href="https://mybx.qiyukf.com/client?k=3c8895c6a0ef81d5d7624a1e50d82a68&wp=1&gid=1144605"
            target="_blank"
          >咨询了解 +</a>
          </div>
        </div>
        <div class="list-item">
          <div class="content-box">
            <div class="desc">
              <img class="icon" src="../../assets/img/aboutus/personal.png" alt="">
              <div class="name">个人客户</div>
              <div class="name-en">RETAIL&nbsp;&emsp;CLIENT</div>
            </div>
            <a
            class="btn-more"
            href="https://mybx.qiyukf.com/client?k=3c8895c6a0ef81d5d7624a1e50d82a68&wp=1&gid=1144605"
            target="_blank"
          >咨询了解 +</a>
          </div>
        </div>
        <div class="list-item">
          <div class="content-box">
            <div class="desc">
              <img class="icon" src="../../assets/img/aboutus/group.png" alt="">
              <div class="name">团体客户</div>
              <div class="name-en">GROUP&nbsp;&emsp;CLIENT</div>
            </div>
            <a
            class="btn-more"
            href="https://mybx.qiyukf.com/client?k=3c8895c6a0ef81d5d7624a1e50d82a68&wp=1&gid=1144605"
            target="_blank"
          >咨询了解 +</a>
          </div>
        </div> -->
      </div>
    </div>
    <div @click.stop="isShowVideoBox = false" v-if="isShowVideoBox" class="video-modal">
      <div @click.stop="isShowVideoBox = true" class="player-container">
        <!-- <vue-core-video-player autoplay :src="introData[1].content"></vue-core-video-player> -->
        <video-player  class="video-player vjs-custom-skin"
          ref="videoPlayer" 
          :playsinline="true" 
          :options="playerOptions"
        ></video-player>
        <div @click.stop="isShowVideoBox = false" class="close">
          <img src="../../assets/img/common/common_add_white.png" alt="">
        </div>
      </div>      
    </div>
    <el-dialog
      title="视频列表"
      :visible.sync="dialogVisible"
      :width="isMobile ? '100%' : '50%'">
      <div class="intro-video-list" style="width: 100%; display: flex; flex-wrap: wrap;">
        <div @click="playVideo(item)" v-for="(item, index) in video_data_origin" :key="index" style="cursor: pointer; width: 25%; box-sizing: border-box; padding: 0 .05rem; .05rem;" class="list-item">
          <div class="cover" style="width: 100%"><img style="width: 100%;" :src="item.content.cover_url" alt=""></div>
          <div style="font-size: .14rem; padding: .1rem; background-color: rgba(0, 0 , 0, .4); color: #ffffff;" class="name">{{item.position}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ItemTitle from '@/components/aboutUs/itemTitle'
import { getAboutUsIntroData, getAboutUsMainBusiness, getVideos } from '@/api/aboutUs'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    ItemTitle
  },
  data () {
    return {
      isMobile: isMobile(),
      isShowVideoBox: false,
      introData: null,
      playerOptions : {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: "" //url地址
        }],
        // poster: "../../static/images/test.jpg", //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true  //全屏按钮
        }
      },
      main_business: [],
      video_bg: '',
      company_intro: '',
      company_intro_brief: '',
      company_intro_img: '',
      company_slogan: '',
      video_data: [],
      video_data_origin: [],
      dialogVisible: false
    }
  },
  created () {
    this.getIntroData()
    this.getMainBusinessData()
    this.getVideos()
  },
  mounted () {
    let _this = this
    this.$nextTick(() => {
      this.timer = setInterval(function () {
        
        if (document.querySelector('.el-carousel__container')) {
          clearInterval(_this.timer)
          let height = document.querySelector('.el-carousel__container .el-carousel__item').offsetWidth / (16/9)
          document.querySelector('.el-carousel__container').style.height = height + 'px'
        }
      }, 200)
      
    })
    window.onresize = function () {
      if (document.querySelector('.el-carousel__container')) {
          clearInterval(_this.timer)
          let height = document.querySelector('.el-carousel__container .el-carousel__item').offsetWidth / (16/9)
          document.querySelector('.el-carousel__container').style.height = height + 'px'
        }
    }
  },
  methods: {
    
    playVideo (item) {
      this.playerOptions.sources[0].src = item.content.video_src
      this.isShowVideoBox = true
    },
    // 获取视频数据
    async getVideos () {
      const res = await getVideos()
      res.list.forEach(item => {
        item.content = JSON.parse(item.content)
        item.order_value = item.content.order_value
      })
      let a = JSON.parse(JSON.stringify(res.list))
      for(let i = 0;i < a.length; i++){
          for(let j = 0; j < a.length - 1; j++){
              if(a[j].order_value<a[j+1].order_value){
                  let b=a[j]
                  a[j]=a[j+1]
                  a[j+1]=b
              }
          }
      }
      for(let i = 0; i < a.length; i++){
          for(let j = 0; j < a.length - 1; j++){
              if(a[j].order_value == a[j+1].order_value && a[j].id<a[j+1].id){
                  let b=a[j]
                  a[j]=a[j+1]
                  a[j+1]=b
              }
          }
      }
      this.video_data_origin = a
      this.video_data = JSON.parse(JSON.stringify(a)).splice(0, 3)
    },
    // 获取主营业务数据
    async getMainBusinessData () {
      const res = await getAboutUsMainBusiness()
      res.list.forEach(item => {
        item.content = JSON.parse(item.content)
      })
      this.main_business = res.list
    },
    // 跳转客服
    jumpService () {
      window.open("https://v7-webchat.7moor.com/?accessId=ILwj5de78f54oT74&imUrlTitle=&imFromUrl=");
    },
    // 获取明亚简介数据
    async getIntroData () {
      const res = await getAboutUsIntroData()
      res.list = res.list.filter(item => {return item.id != 6})
      this.introData = res.list
      this.video_bg = res.list.filter(item => { return item.id == 5})[0].content
      this.company_intro_brief = res.list.filter(item => { return item.id == 7})[0].content
      this.company_intro = res.list.filter(item => { return item.id == 8})[0].content
      this.company_intro_img = res.list.filter(item => { return item.id == 10})[0].content
      this.company_slogan = res.list.filter(item => { return item.id == 9})[0].content
      this.playerOptions.sources[0].src = res.list[1].content
      this.$emit('loading', false)
    }
  }
}
</script>
<style>
.el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  background-color: #fe6917;
}
button.el-carousel__arrow {
  outline: none !important;
}
.el-carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0; */
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
<style lang="scss">
.vjs-custom-skin > .video-js .vjs-big-play-button {
  width: .7rem!important;
  height: .7rem!important;
  line-height: .7rem!important;
  border-radius: 50%;
  border: 0 none;
  transition: all .3s;
  
}
.video-js:hover .vjs-big-play-button, .vjs-custom-skin > .video-js .vjs-big-play-button:focus, .vjs-custom-skin > .video-js .vjs-big-play-button:active {
  background-color: #fe6917;
  box-shadow:0 0 0px .1rem rgba(255, 255, 255,0.2); 
}
.mobile-about-us {
  .vjs-custom-skin > .video-js .vjs-big-play-button {
    transform: translateX(-50%) translateY(-50%);
    margin-top: 0!important;
    margin-left: 0!important;
    

  }
}
</style>
<style lang="scss" scoped>
.videos-box {
  margin-top: .83rem;
}
.more-video {
    width: 1.6rem;
    height: 0.6rem;
    border-radius: 0.3rem;
    background-color: #fe6917;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: .4rem auto 0;
    font-size: 0.18rem;
    cursor: pointer;
    transition: all 0.8s;
    &:hover {
      transform: scale(1.2);
    }
    img {
      width: 0.16rem;
      height: 0.16rem;
      margin-left: 0.08rem;
      }

}
.video-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.6);
  z-index: 99999;
  .player-container {
    width: 70%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    .close {
      width: .9rem;
      height: .9rem;
      background-color: #FE6917;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: .5rem auto;
      cursor: pointer;
      img {
        width: 45%;
        transform: rotate(45deg);
      }
    }
  }
}
a {
  text-decoration: none;
}
.mobile-about-introduction.about-introduction {
  .video-modal .player-container {
    width: 90%;
  }
  margin-left: 0;
  .main-business {
    max-width: 100%;
    margin: 0 auto 0;
    margin-top: 20px;
    padding-bottom: .5em;
    color: #FE6917;
    .mobile-business-list {
      margin-top: 15px;
      display: flex;
      align-items: center;
      overflow: hidden;
      border-radius: .2rem;
      .list-item {
        flex:1;
        height: auto;
        background-color: rgba(0,0,0,.4);
        background-size: 100%;
        // &:nth-child(1) {
        //   background-image: url(../../assets/img/aboutus/life.jpg);
        // }
        // &:nth-child(2) {
        //   background-image: url(../../assets/img/aboutus/property.jpg);
        // }
        // &:nth-child(3) {
        //   background-image: url(../../assets/img/aboutus/personal.jpg);
        // }
        // &:nth-child(4) {
        //   background-image: url(../../assets/img/aboutus/group.jpg);
        // }
        .content-box {
          width: 100%;
          height: auto;        
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          line-height: normal;
          position: relative;
          .zw {
            width: 100%;
            height: auto;
            opacity: 0;
          }
          // transition: all .3s;
          // background: linear-gradient(rgba(242, 116, 46, 0),rgba(231, 170, 50, 0));
          // &:hover {
          //   background: linear-gradient(rgba(242, 116, 46,.9),rgba(231, 170, 50,.9));
          //   .btn-more {
          //     // transform: translateY(.69rem);
          //     display: block;
          //   }
          //   .desc {
          //     padding-bottom: .69rem;
          //   }
          // }
        }
        
        .desc {
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          color: #ffffff;
          padding-bottom: 0;
          transition: all .5s;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          height: 100%;
          padding: 0.2rem 0;
          .icon {          
            width: 50%;
            height: auto;
          }
          .name {
            font-size: 12px;
            margin-top: 0;
          }
          .name-en {
            color: rgba(255, 255, 255, .4);
            font-size: 12px;
            margin-top: 0;
            letter-spacing: 0;
            transform: scale(.9);
            height: 2.6em;
          }
        }
        .btn-more {
          width: 1.2rem;
          height: .4rem;
          line-height: .4rem;
          text-align: center;       
          background: #FEFEFE;
          border-radius: .2rem;
          transform: translateY(0rem);
          display: none;
          cursor: pointer;
          transition: transform .3s;
          font-size: .14rem;
          color: #333333;
        }
      }
    }
    .head {
      .cn {
        font-size: 16px;
        letter-spacing: .2rem;
      }
      .en {
        font-size: 12px;
        letter-spacing: .4rem;
        margin-top: .1rem;
        opacity: .5;
        span {
          display: inline-block;
          margin-right: .3rem;
        }
      }
    }
  }
  .introduction-content {
    padding-top: 0;
    .section-4 {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .slogan {
          flex: 1;
          color: #3453A2;
          font-size: 14px;
          padding: 0 15px;
          font-weight: bold;
        }
        img {
          width: 20px;
          height: auto;
        }
      }
    .section-3 {    
        margin-top: 20px;
        pre {
          color: #7D7D7D;
          font-size: 12px;
          line-height: 22px;
        }
      }
    .section-2 {
      margin-top: 20px;
      img {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;
      }
    }
    .section-1 {
      display: block;
      .video-box {
        width: 100%;
        height: auto;
        .bg {
          width: 100%;
          opacity: 0;
        }
        .btn-play {
          width: 50px;
          height: 50px;
        }
      }
      .company {
        margin-left: 0;
      }
      .company-name {
        margin-top: 20px;
        position: relative;
        .en {
          font-size: 32px;
          color: #F4F4F4;
          margin-left: 0.31rem;
          letter-spacing: 25px;
          line-height: 42px;
          font-weight: bold;
        }
        .cn {
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 16px;
        }
      }
      .brief {
        color: #7D7D7D;
        font-size: 12px;
        line-height: 22px;
        margin-top: .2rem;
        pre {
          font-size: 12px;
          line-height: 22px;
        }
      }
    }
  }
}
.about-introduction {  
  margin-left: 0.6rem;
}
@keyframes shine{
  0%{
      box-shadow:0 0 0px 0px rgba(241, 105, 31,0.9);
  }
  100%{
      box-shadow:0 0 0px 25px rgba(245, 10, 213,0); 
  }
}

.introduction-content {
  padding-top: 1.19rem;
  .section-4 {
    display: flex;
    align-items: center;
    margin-top: .7rem;
    .slogan {
      flex: 1;
      color: #3453A2;
      font-size: .24rem;
      padding: 0 .43rem;
      font-weight: bold;
    }
    img {
      width: .88rem;
      height: .71rem;
    }
  }
  .section-3 {    
    margin-top: .72rem;
    pre {
      color: #7D7D7D;
      font-size: .16rem;
      line-height: .34rem;
    }
  }
  .section-2 {
    margin-top: .86rem;
    img {
      width: 8.13rem;
      height: 1.05rem;
      margin: 0 auto;
      display: block;
    }
  }
  .section-1 {
    display: flex;
    .video-box {
      width: 3.94rem;
      height: 2.46rem;
      border-radius: 1.1rem 0 1.1rem 0;
      // background-color: red;
      background-size: 100% 100%;
      background-position: center;
      overflow: hidden;
      position: relative;
      transition: all .5s;
      &:hover {
        background-size: 130% 130%;
      }
      .btn-play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(1);
        width: .58rem;
        height: .58rem;
        border-radius: 50%;
        cursor: pointer;
        transition: all .5s;
        &:hover {
           animation: shine 0.8s linear infinite;
        }
      }
    }
    .company {
      margin-left: .46rem;
      flex: 1;
      .company-name {
        position: relative;
        .en {
          font-size: 1.1rem;
          color: #F4F4F4;
          margin-left: 0.31rem;
          letter-spacing: .2rem;
          line-height: 1rem;
          font-weight: bold;
        }
        .cn {
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: .36rem;
        }
      }
      .brief {
        color: #7D7D7D;
        font-size: .16rem;
        line-height: .34rem;
        margin-top: .2rem;
        pre {
          font-size: .16rem;
          line-height: .34rem;
        }
      }
    }
  }
}
.main-business {
  max-width: 14rem;
  margin: 0 auto 0;
  margin-top: .6rem;
  // transform: translateX(-3rem);
  color: #FE6917;
  .business-list {
    margin-top: .5rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: .1rem;
    .list-item {
      width: 2.6rem;
      height: 4.8rem;
      background-color: rgba(0,0,0,.4);
      background-size: 100%;
      // &:nth-child(1) {
      //   background-image: url(../../assets/img/aboutus/life.jpg);
      // }
      // &:nth-child(2) {
      //   background-image: url(../../assets/img/aboutus/property.jpg);
      // }
      // &:nth-child(3) {
      //   background-image: url(../../assets/img/aboutus/personal.jpg);
      // }
      // &:nth-child(4) {
      //   background-image: url(../../assets/img/aboutus/group.jpg);
      // }
      .content-box {
        flex: 1;
        height: 5rem;        
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: normal;
        transition: all .3s;
        background: linear-gradient(rgba(242, 116, 46, 0),rgba(231, 170, 50, 0));
        &:hover {
          background: linear-gradient(rgba(242, 116, 46,.9),rgba(231, 170, 50,.9));
          .btn-more {
            // transform: translateY(.69rem);
            display: block;
          }
          .desc {
            padding-bottom: .69rem;
          }
        }
      }
      
      .desc {
        text-align: center;
        color: #ffffff;
        padding-bottom: 0;
        transition: all .5s;
        .icon {          
          width: 1.08rem;
          height: 1.08rem;
        }
        .name {
          font-size: .24rem;
          margin-top: .2rem;
        }
        .name-en {
          color: rgba(255, 255, 255, .4);
          font-size: .14rem;
          margin-top: .3rem;
          letter-spacing: .03rem;
        }
      }
      .btn-more {
        width: 1.2rem;
        height: .4rem;
        line-height: .4rem;
        text-align: center;       
        background: #FEFEFE;
        border-radius: .2rem;
        transform: translateY(0rem);
        display: none;
        cursor: pointer;
        transition: transform .3s;
        font-size: .14rem;
        color: #333333;
      }
    }
  }
  .head {
    .cn {
      font-size: .36rem;
      letter-spacing: .2rem;
    }
    .en {
      font-size: .24rem;
      letter-spacing: .4rem;
      margin-top: .1rem;
      opacity: .5;
      span {
        display: inline-block;
        margin-right: .3rem;
      }
    }
  }
}
pre {
  margin-bottom: 0;
  font-family: 'Microsoft YaHei', Arial, sans-serif;
  text-align: justify;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>